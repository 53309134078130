import { render, staticRenderFns } from "./FinaceDetails.vue?vue&type=template&id=768bed27&scoped=true"
import script from "./FinaceDetails.vue?vue&type=script&lang=js"
export * from "./FinaceDetails.vue?vue&type=script&lang=js"
import style0 from "./FinaceDetails.vue?vue&type=style&index=0&id=768bed27&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "768bed27",
  null
  
)

export default component.exports