<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;" @click="$router.back()">结算管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">结算列表</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">备注</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <h3>收款信息</h3>
            <div>
              <el-form
                :model="ruleForm"
                ref="ruleForm"
                label-width="150px"
                class="demo-ruleForm"
              >
                <el-form-item
                  label="国家补贴标准"
                  prop="nationalSubsidyStandard"
                >
                  <el-input
                    v-model="ruleForm.nationalSubsidyStandard"
                    size="small"
                    clearable
                    oninput="value=value.replace(/[^\d]/g,'')"
                  ></el-input>
                </el-form-item>
                <el-form-item label="收款结算人数" prop="settlementPeople">
                  <el-input
                    v-model="ruleForm.settlementPeople"
                    size="small"
                    clearable
                    oninput="value=value.replace(/[^\d]/g,'')"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="应收平台技术服务费"
                  prop="platformTechnServiceCost"
                >
                  <el-input
                    v-model="ruleForm.platformTechnServiceCost"
                    size="small"
                    clearable
                  ></el-input>
                </el-form-item>
                <el-form-item label="应收课程服务费" prop="courseServiceCost">
                  <el-input
                    v-model="ruleForm.courseServiceCost"
                    size="small"
                    clearable
                  ></el-input>
                </el-form-item>
                <el-form-item label="应收平台服务费" prop="platformServiceCost">
                  <el-input
                    v-model="ruleForm.platformServiceCost"
                    size="small"
                    clearable
                  ></el-input>
                </el-form-item>
                <el-form-item label="收款结算单号" prop="settlementNum">
                  <el-input
                    v-model="ruleForm.settlementNum"
                    size="small"
                    clearable
                  ></el-input>
                </el-form-item>
                <el-form-item label="开票情况" prop="openBill">
                  <el-select
                    v-model="ruleForm.openBill"
                    placeholder="请选择开票情况"
                    clearable
                    size="small"
                  >
                    <el-option
                      v-for="item in options"
                      :key="item.openBill"
                      :label="item.label"
                      :value="item.openBill"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="结款金额" prop="settlementAmount">
                  <el-input
                    v-model="ruleForm.settlementAmount"
                    size="small"
                    clearable
                  ></el-input>
                </el-form-item>
                <el-form-item label="确认收入月份" prop="arriveDate">
                  <el-date-picker
                    size="small"
                    clearable
                    v-model="ruleForm.arriveDate"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    type="date"
                    placeholder="确认收入月份"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-form>
            </div>
            <el-divider></el-divider>
            <h3>付款信息</h3>
            <div>
              <div
                style="display:flex;width:33%;align-items:center;padding:15px"
              >
                <span style="width:120px">付款结算人数:</span
                ><el-input
                  v-model="arrivePeople"
                  size="small"
                  clearable
                  oninput="value=value.replace(/[^\d]/g,'')"
                ></el-input>
              </div>
              <div
                v-for="(item, index) in addlist"
                :key="index"
                style="background:#f3f3f3;margin-bottom: 15px;padding: 20px;"
              >
                <el-form
                  :model="item"
                  ref="ruleForm"
                  label-width="180px"
                  class="demo-ruleForm"
                >
                  <el-form-item
                    :label="'课程提供方' + serialConversion(index) + ':'"
                    prop="courseProvide"
                  >
                    <el-input
                      v-model="item.courseProvide"
                      size="small"
                      clearable
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    :label="'应付课程方' + serialConversion(index) + '课程费:'"
                    prop="courseCost"
                  >
                    <el-input
                      v-model="item.courseCost"
                      size="small"
                      clearable
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    :label="'课程方' + serialConversion(index) + '付款结算单号:'"
                    prop="fdBillNo"
                  >
                    <el-input
                      v-model="item.fdBillNo"
                      size="small"
                      clearable
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    :label="'课程方' + serialConversion(index) + '付款时间:'"
                    prop="paymentDate"
                  >
                    <el-date-picker
                      size="small"
                      clearable
                      format="yyyy-MM-dd"
                      value-format="yyyy-MM-dd"
                      v-model="item.paymentDate"
                      type="date"
                      placeholder="确认收入月份"
                    >
                    </el-date-picker>
                  </el-form-item>
                  <el-form-item
                    :label="'是否收到课程方' + serialConversion(index) + '发票:'"
                    prop="hasAcceptBill"
                  >
                    <el-select
                      v-model="item.hasAcceptBill"
                      placeholder="请选择开票情况"
                      clearable
                      size="small"
                    >
                      <el-option
                        v-for="item in options2"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-form>
                <div class="df zsBtn" style="justify-content: flex-end;">
                  <el-button
                    v-if="addlist.length > 1"
                    @click="delCertificate(index)"
                    type="primary"
                    round
                    size="mini"
                    class="bgc-bv"
                    >删除</el-button
                  >
                  <el-button
                    v-if="index == addlist.length - 1 && index != 5"
                    @click="certificate"
                    type="primary"
                    round
                    size="mini"
                    class="bgc-bv"
                    >新增</el-button
                  >
                </div>
              </div>
            </div>
            <el-divider></el-divider>
            <h3>其他信息</h3>
            <div>
              <el-form
                :model="ruleForm"
                ref="ruleForm"
                label-width="100px"
                class="ruleFormOther"
              >
                <el-form-item label="备注1：" prop="remark1">
                  <el-input
                    v-model="ruleForm.remark1"
                    size="small"
                    clearable
                    type="textarea"
                  ></el-input>
                </el-form-item>
                <el-form-item label="备注2：" prop="remark2">
                  <el-input
                    v-model="ruleForm.remark2"
                    size="small"
                    clearable
                    type="textarea"
                  ></el-input>
                </el-form-item>
                <el-form-item label="备注3：" prop="remark3">
                  <el-input
                    v-model="ruleForm.remark3"
                    size="small"
                    clearable
                    type="textarea"
                  ></el-input>
                </el-form-item>
                <el-form-item label="备注4：" prop="remark4">
                  <el-input
                    v-model="ruleForm.remark4"
                    size="small"
                    clearable
                    type="textarea"
                  ></el-input>
                </el-form-item>
                <el-form-item label="备注5：" prop="remark5">
                  <el-input
                    v-model="ruleForm.remark5"
                    size="small"
                    clearable
                    type="textarea"
                  ></el-input>
                </el-form-item>
                <el-form-item label="备注6：" prop="remark6">
                  <el-input
                    v-model="ruleForm.remark6"
                    size="small"
                    clearable
                    type="textarea"
                  ></el-input>
                </el-form-item>
              </el-form>
            </div>
            <div style="display:flex; justify-content: center;">
              <el-button @click="back()" type="primary" class="bgc-bv"
                >取消</el-button
              >
              <el-button @click="doSave" type="primary" class="bgc-bv"
                >保存</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "FinaceDetails",
  components: {},
  data() {
    return {
      ruleForm: {
        nationalSubsidyStandard: "", //国家补贴标准
        settlementPeople: "", //收款结算人数
        platformTechnServiceCost: "", //应收平台技术服务费
        courseServiceCost: "", //应收平台课程服务费
        platformServiceCost: "", //应收平台服务费
        settlementNum: "", //收款结算单
        openBill: "", // 收款结算单
        settlementAmount: "", // 结款金额
        arriveDate: "", // 确认收入月份
        remark1: "", //备注1
        remark2: "", //备注2
        remark3: "", //备注3
        remark4: "", //备注4
        remark5: "", //备注5
        remark6: "", //备注6
      },
      arrivePeople: "",
      options: [
        {
          openBill: 1,
          label: "是",
        },
        {
          openBill: 0,
          label: "否",
        },
      ],
      options2: [
        {
          value: 1,
          label: "是",
        },
        {
          value: 0,
          label: "否",
        },
      ],
      addlist: [], // 新增列表
      addobj: {
        courseProvide: "", //课程提供方
        courseCost: "", //应付课程方一课程费
        fdBillNo: "", //课程方一付款结算单号
        paymentDate: "", //课程方一付款时间
        hasAcceptBill: "", //是否收到课程方一发票
      },
      serials: ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十', '十一', '十二', '十三', '十四', '十五', '十六', '十七', '十八', '十九', '二十']
    };
  },
  created: function() {
    this.getInfo(this.$route.query.projectId);
  },
  methods: {
    /* 新增 */
    certificate() {
      if (this.addlist == 6) {
        return;
      } else {
        this.addlist.push({ ...this.addobj });
      }
    },
    /* 删除 */
    delCertificate(ind) {
      this.addlist.splice(ind, 1);
    },
    /* 查询 */
    getInfo(projectId) {
      this.$post("/biz/settlement/projectremark/query", { projectId })
        .then((ret) => {
          let retData = ret.data || {};
          var data = JSON.stringify(retData) == "{}";
          if (!data) {
            this.addlist = retData.infos || [{ ...this.addobj }];
            this.addlist.map((el) => {
              if (el.paymentDate) {
                el.paymentDate = el.paymentDate.replaceAll("/", "-");
              }
            });
            this.arrivePeople = retData.arrivePeople;
            (this.ruleForm = {
              ...retData,
            }),
              (this.ruleForm.arriveDate = this.ruleForm.arriveDate.replaceAll(
                "/",
                "-"
              ));
          } else {
            this.addlist.push({ ...this.addobj });
          }
        })
        .catch((err) => {
          return;
        });
    },
    /* 保存 */
    doSave() {
      this.addlist.map((el) => {
        el.projectId = this.$route.query.projectId;
      });
      this.addlist.filter((item) => {
        delete item.createTime;
        delete item.createId;
      });
      let parmar = {
        projectId: this.$route.query.projectId,
        nationalSubsidyStandard: this.ruleForm.nationalSubsidyStandard, //国家补贴标准
        settlementPeople: this.ruleForm.settlementPeople, //收款结算人数
        platformTechnServiceCost: this.ruleForm.platformTechnServiceCost, //应收平台技术服务费
        courseServiceCost: this.ruleForm.courseServiceCost, //应收平台课程服务费
        platformServiceCost: this.ruleForm.platformServiceCost, //应收平台服务费
        settlementNum: this.ruleForm.settlementNum, //收款结算单
        openBill: this.ruleForm.openBill, // 收款结算单
        settlementAmount: this.ruleForm.settlementAmount, // 结款金额
        arriveDate: this.ruleForm.arriveDate, // 确认收入月份
        remark1: this.ruleForm.remark1, //备注1
        remark2: this.ruleForm.remark2, //备注2
        remark3: this.ruleForm.remark3, //备注3
        remark4: this.ruleForm.remark4, //备注4
        remark5: this.ruleForm.remark5, //备注5
        remark6: this.ruleForm.remark6, //备注6
        infos: this.addlist,
        arrivePeople: this.arrivePeople,
      };
      this.$post("/biz/settlement/projectremark/modify", parmar)
        .then((ret) => {
          if (ret.status == "0") {
            this.$message({
              message: "保存成功",
              type: "success",
            });
            this.$router.push({
              path: "/web/evaluate/FinanceList",
            });
          }
        })
        .catch((err) => {
          return;
        });
    },
    back() {
      this.$router.push({
        path: "/web/evaluate/FinanceList",
        query: {
          refresh: true,
        },
      });
    },
    serialConversion(v) {
      if (this.serials.length < (v + 1)) {
         return (v + 1);
      }
      return this.serials[v];
    }
  },
};
</script>

<style lang="less" scoped>
h3 {
  padding: 0.75rem;
  position: relative;
  &:before {
    content: "";
    border-left: 0.25rem solid #6676ff;
    width: 0.25rem;
    position: absolute;
    height: 1rem;
    left: 0;
    top: 0.95rem;
  }
}
.demo-ruleForm {
  display: flex;
  flex-wrap: wrap;
  .el-form-item {
    width: 33%;
  }
}
.ruleFormOther {
  display: flex;
  flex-wrap: wrap;
  .el-form-item {
    width: 50%;
    /deep/.el-textarea__inner {
      min-height: 7.5rem !important;
      resize: none;
    }
  }
}
</style>
